.insert-main {
    position: relative;
    height: 100%;
}

.insert-main .insert-select {
    width: 300px;
    padding: 0 1px 20px 10px;
    display: inline-block;
}

.insert-main  .insert-action {
    z-index: 99;
    padding: 0 5px 0 5px;
    float: right;
}

.insert-main  .insert-action button{
    background-color: yellow;
    border-radius: 5px;
    width: 100px;
    height: 40px;
}

.insert-main .insert-data {
    border: 2px solid green;
    height: 40%;
    overflow-y: auto;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
}

.insert-main .insert-data div {
    padding: 0 10px 0 10px;
    text-align: center;
}

.insert-data div div {
    width: 300px;
    display: inline-block;
    padding: 0 10px 0 10px;
}

.insert-data div label {
    width: 20%;
}

.insert-data div input,
.insert-data div select {
    width: 90%;
}

.insert-table {
    margin-top: 30px;
    padding: 20px 0 0 0;
    width: 100%;
    height: 60%;
    position: absolute;
    overflow: auto;
    text-align: center;
    align-items: center;
    border: 2px solid green;
 }
 
 .insert-table table {
    width: 100%;
    position: relative;
    text-align: center;
 }