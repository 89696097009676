.view-main {
    position: relative;
    height: 100%;
}

.view-main .view-select {
    width: 300px;
    display: inline-block;
    padding: 0 1px 20px 10px;
}

.view-main .view-data {
    border: 2px solid green;
    height: 30%;
    overflow-y: auto;
    text-align: center;
    clear: right;
    display: flex;
    justify-content: center;
    align-items: center;
    clear: right;
}

.view-main .view-data div {
    padding: 0 10px 0 10px;
    text-align: center;
}

.view-data div div {
    width: 300px;
    display: inline-block;
    padding: 0 10px 0 10px;
}

.view-data div label {
    width: 20%;
}

.view-data div input,
.view-data div select {
    width: 90%;
}

.view-pagination {
    text-align: center;
    right: 0;
    padding: 5px 0 0 0;
    z-index: 99;
}

.pointeron {
    cursor: pointer;
}

.pointeroff {
    pointer-events: none;
    opacity: 0.6;
}

#extremepage {
    font-size: 20px;
}

.view-pagination ul li {
    display: inline-block;
    margin: 3px;
    z-index: 99;
    color: yellow;
    font-weight: bold;
}

.view-pagination ul li a {
    cursor: pointer;
    pointer-events: painted;
}

.view-table {
    margin-top: 20px;
    width: 100%;
    height: 40%;
    overflow: auto;
    text-align: center;
    align-items: center;
    border: 2px solid green;
    display: flex;
    text-align: center;
}

/*.view-table table {
    width: 100%;
    position: relative;
    text-align: center;
} */
/* .view-table table {
    width: 100%;
}

.view-table table tbody {
    padding: 20px 0 0 0;
    width: 100%;
    height: 40%;
    position: absolute;
    overflow: auto;
    left: 0;
    right: 0;
    margin: auto
} */


.view-tablefk table {
    width: 100%;
    position: relative;
    text-align: center;
}

.view-main .view-action {
    position: relative;
    z-index: 99;
    padding: 0 5px 0 5px;
    float: right;
}

.view-main .view-action button {
    background-color: yellow;
    border-radius: 5px;
    width: 100px;
    height: 40px;
}