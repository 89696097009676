.profile-main{
    position: relative;
    height: 100%;
}

.profile-main .profile-action {
    position: relative;
    z-index: 1;
    float: right;
    padding: 0 5px 15px 5px;
}

.profile-main .profile-action button {
    background-color: yellow;
    border-radius: 5px;
    width: 100px;
    height: 40px;
}

.profile-data {
    height: 60%;
    overflow-y: auto;
    text-align: center;
    clear: right;
    border: 2px solid green;
    display: flex;
    justify-content:center ;
    align-items: center;
}

.profile-data .profile-value {
    padding: 20px 10px 20px 10px;
}

.profile-main .profile-data div .profile-select,
.profile-main .profile-data div .profile-input {
    width: 330px;
    padding: 0 1px 20px 10px;
    display: inline-block;
}

.profile-main .profile-data div .profile-input input,
.profile-main .profile-data div .profile-input select {
    width: 100%;
    height: 33px;
    border-radius: 5px;
    display: inline-block;
    border: 2px solid green;
}

.profile-table {
    margin-top: 30px;
    padding: 20px 0 0 0;
    width: 100%;
    height: 60%;
    position: absolute;
    overflow: auto;
    text-align: center;
    align-items: center;
    border: 2px solid green;
 }
 
 .profile-table table {
    width: 100%;
    position: relative;
    text-align: center;
 }