.export-main {
    position: relative;
    height: 100%;
}

.export-main .export-select {
    width: 300px;
    display: inline-block;
    padding: 0 1px 20px 10px;
}

.export-main .export-data {
    border: 2px solid green;
    height: 40%;
    overflow-y: auto;
    text-align: center;
    clear: right;
    display: flex;
    justify-content:center ;
    align-items: center;
    clear:right;
}

.export-main .export-data div {
    padding: 0 10px 0 10px;
    text-align: center;
}

.export-data div div {
    width: 300px;
    display: inline-block;
    padding: 0 10px 0 10px;
}

.export-data div label {
    width: 20%;
}

.export-data div input,
.export-data div select {
    width: 90%;
}

.export-table {
    margin-top: 20px;
    width: 100%;
    height: 40%;
    overflow: auto;
    text-align: center;
    align-items: center;
    border: 2px solid green;
    display: flex;
    text-align: center;
}

/*.export-table table {
    width: 100%;
    position: relative;
    text-align: center;
} */
/* .export-table table {
    width: 100%;
}

.export-table table tbody {
    padding: 20px 0 0 0;
    width: 100%;
    height: 40%;
    position: absolute;
    overflow: auto;
    left: 0;
    right: 0;
    margin: auto
} */


.export-tablefk table {
    width: 100%;
    position: relative;
    text-align: center;
}

.export-main .export-action {
    position: relative;
    z-index: 99;
    padding: 0 5px 0 5px;
    float: right;
}

.export-main .export-action button {
    background-color: yellow;
    border-radius: 5px;
    width: 120px;
    height: 40px;
}