body {
  font-family: Verdana, sans-serif;
  font-size: 14px;
  background-color: #070e27 !important;
}

span,
div {
  color: white;
}

.ok {
  width: 20%;
  position: fixed;
  padding: 0.5% 0.5% 0.5% 0.5%;
  border: 2px solid;
  border-radius: 5px;
  right: 1%;
  bottom: 15%;
  z-index: 1;
  color: white;
  height: auto;
  overflow: hidden;
  -webkit-animation: fadeinout 3s linear forwards;
  animation: fadeinout 8s linear forwards;
  background: green;
}

.ko {
  width: 20%;
  position: fixed;
  padding: 0.5% 0.5% 0.5% 0.5%;
  border: 2px solid;
  border-radius: 5px;
  right: 1%;
  bottom: 15%;
  z-index: 1;
  color: white;
  height: auto;
  overflow: hidden;
  -webkit-animation: fadeinout 3s linear forwards;
  animation: fadeinout 8s linear forwards;
  background: red;
}

@-webkit-keyframes fadeinout {

  0%,
  100% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }
}

@keyframes fadeinout {

  0%,
  100% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }
}

.app-main {
  position: absolute;
  width: 100%;
  height: 95%;
}

.app-title {
  position: relative;
  border-radius: 5px;
  padding: 10px 5px 5px 5px;
  text-align: right;
  font-weight: bold;
  font-size: 26px;
}

.app-title #name {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  display: inline-block;
  width: 30%;
  max-height: 40%;
  color: grey
}

.app-title #function {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  display: inline-block;
  width: 30%;
  max-height: 40%;
}

.login-main {
  width: 800px;
  border: 3px solid red;
  border-radius: 10px;
  height: 400px;
  top: 0;
  bottom: 0;
  position: absolute;
  margin: auto;
  right: 0;
  left: 0;
}

.login-internal {
  height: 50%;
  width: 50%;
  margin: auto;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  position: absolute;
}

.login-internal div {
  height: 60%;
}

.login-label {
  display: block;
  color: yellow;
  text-align: center;
}

.login-input {
  width: 100%;
}

.login-action {
  position: absolute;
  bottom: 0;
  right: 0;
  padding: 0 3% 3% 0;
}

.login-action button {
  height: 60px;
  width: 140px;
  background-color: green;
  border-radius: 2px;
  border: none;
  border-radius: 5px;
}

.menu-main {
  position: relative;
  border: 3px solid blue;
  padding: 1% 1% 1% 1%;
  width: 100%;
  height: 100%;
}

.menu-menu {
  width: 220px;
  padding: 2px 2px 2px 2px;
  float: left;
  text-align: left;
  padding: 0 0 0 10px;
  /*crt*/
  margin: 0 10px 10px 0;
  /* max-height: 80%; */
  height: 50%;
  /* height: 30%; */
  overflow-y: auto;
  text-align: center;
  position: relative;
  z-index: 99;
  border: 1px solid blue;
}

.menu-menu div {
  width: 100%;
  margin: 5px 0 0 0;
}

.menu-menu div:hover {
  cursor: pointer;
}

.menu-menu div #left {
  display: inline-block;
  width: 17%;
  text-align: center;
}

.menu-menu div #right {
  display: inline-block;
  width: 83%;
  text-align: center;
}

.menu-body {
  height: 100%;
  padding: 7px 10px 7px 10px;
  overflow-y: auto;
  position: relative;
  z-index: 1;
}

.menu-menu,
.menu-body {
  border-radius: 5px;
}

.menu-menu div .icon {
  text-align: center;
  color: green;
  width: 30px;
}

.testcolor {
  color: yellow !important;
  font-weight: bold;
}

.standardcolor {
  color: white;
}

select {
  height: 30px;
}

tbody {
  display: block;
  height: 90px;
  overflow: auto;
}

thead,
tbody tr {
  display: table;
  width: 100%;
  table-layout: fixed;
}

thead {
  width: calc(100% - 1em)
}

th {
  text-overflow: ellipsis;
  overflow: hidden;
}

.view-tablefk {
  padding: 20px 0 0 0;
  width: 100%;
  height: 60%;
  position: absolute;
  overflow: auto;
  text-align: center;
  align-items: center;
  margin-top: 160px;
}