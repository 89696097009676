.update-main {
    position: relative;
    height: 100%;
}

.update-main .update-select {
    width: 300px;
    display: inline-block;
    padding: 0 1px 20px 10px;
}

.update-main .update-data {
    border: 2px solid green;
    height: 40%;
    overflow-y: auto;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    clear:right;
}

.update-main .update-data div {
    padding: 0 10px 0 10px;
    text-align: center;
}

.update-data div div {
    width: 300px;
    display: inline-block;
    padding: 0 10px 0 10px;
}

.update-data div label {
    width: 20%;
}

.update-data div input,
.update-data div select {
    width: 90%;
}

.update-table {
    margin-top: 20px;
    width: 100%;
    height: 40%;
    /* position: absolute; */
    overflow: auto;
    text-align: center;
    align-items: center;
    border: 2px solid green;
    display: flex;
    justify-content: center;
}

.update-table table {
    width: 100%;
    position: relative;
    text-align: center;
    margin: auto;
}

.update-main .update-button {
    position: relative;
    z-index: 99;
    float: right;
}

.update-main .update-button div {
    display: inline-block;
    padding: 0 5px 0 5px;
}

.update-main .update-button div button {
    background-color: yellow;
    border-radius: 5px;
    width: 100px;
    height: 40px;
}

.update-main .update-button .update-clear button {
    background-color: green !important;
    border-radius: 5px;
    width: 100px;
    height: 40px;
}