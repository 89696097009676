.delete-main {
    position: relative;
    height: 100%;
}

.delete-main .delete-select {
    width: 300px;
    display: inline-block;
    padding: 0 1px 20px 10px;
}

.delete-main .delete-data {
    border: 2px solid green;
    height: 40%;
    overflow-y: auto;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    clear:right;
}

.delete-main .delete-data div {
    padding: 0 10px 0 10px;
    text-align: center;
}

.delete-data div div {
    width: 300px;
    display: inline-block;
    padding: 0 10px 0 10px;
}

.delete-data div label {
    width: 20%;
}

.delete-data div input,
.delete-data div select {
    width: 90%;
}

.delete-table {
   margin-top: 20px;
    width: 100%;
    height: 40%;
    overflow: auto;
    text-align: center;
    align-items: center;
    border: 2px solid green;
    display: flex;
    align-items: center; 
}

.delete-table table {
    width: 100%;
    position: relative;
    text-align: center;
}
/* .delete-table table {
    width: 100%;
}

.delete-table table tbody {
    padding: 20px 0 0 0;
    width: 100%;
    height: 70%;
    position: absolute;
    overflow: auto;
    left: 0;
    right: 0;
    margin: auto
} */

.delete-main .delete-button {
    position: relative;
    z-index: 99;
    float: right;
}

.delete-main .delete-button div {
    display: inline-block;
    padding: 0 5px 0 5px;
}

.delete-main .delete-button div button {
    background-color: yellow;
    border-radius: 5px;
    width: 100px;
    height: 40px;
}

.delete-main .delete-button .delete-clear button {
   background-color: green !important;
   border-radius: 5px;
   width: 100px;
   height: 40px;
}