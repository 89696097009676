.profileupdate-main {
    position: relative;
    height: 100%;
}

.profileupdate-main .profileupdate-button {
    position: relative;
    z-index: 99;
    float: right;
    padding-bottom:15px;
}

.profileupdate-main .profileupdate-button div {
    display: inline-block;
    padding: 0 5px 0 5px;
}

.profileupdate-main .profileupdate-button div button {
    background-color: yellow;
    border-radius: 5px;
    width: 100px;
    height: 40px;
}

.profileupdate-main .profileupdate-button .profileupdate-clear button {
   background-color: green !important;
   border-radius: 5px;
   width: 100px;
   height: 40px;
}
.profileupdate-data {
    height: 40%;
    overflow-y: auto;
    text-align: center;
    clear: right;
    border: 2px solid green;
    display: flex;
    justify-content: center;
    align-items: center;
    clear:right;
}

.profileupdate-data .profileupdate-value {
    padding: 20px 10px 20px 10px;
}

.profileupdate-main .profileupdate-data div .profileupdate-select,
.profileupdate-main .profileupdate-data div .profileupdate-input {
    width: 330px;
    padding: 0 1px 20px 10px;
    display: inline-block;
}

.profileupdate-main .profileupdate-data div .profileupdate-input input,
.profileupdate-main .profileupdate-data div .profileupdate-input select {
    width: 100%;
    height: 33px;
    border-radius: 5px;
    display: inline-block;
    border: 2px solid green;
}

.profileupdate-table {
    margin-top:20px;
    width: 100%;
    height: 55%;
    overflow: auto;
    text-align: center;
    align-items: center;
    border: 2px solid green;
    display: flex;
}

.profileupdate-table table {
    width: 100%;
    position: relative;
    text-align: center;
}


