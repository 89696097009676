.profileupdate-main {
    position: relative;
    height: 100%;
}

.profileupdate-main .profileupdate-button {
    position: relative;
    z-index: 99;
    padding: 0 1px 20px 10px;

}

.profileupdate-data {
    height: 40%;
    overflow-y: auto;
    text-align: center;
    clear: right;
    border: 2px solid green;
    display: flex;
    justify-content: center;
    align-items: center;
    clear:right;
}

.profileupdate-table {
    margin-top:20px;
    width: 100%;
    height: 40%;
    overflow: auto;
    text-align: center;
    align-items: center;
    border: 2px solid green;
}

.profileupdate-table table {
    width: 100%;
    position: relative;
    text-align: center;
}


