/* .import{
    margin : 20px 0 020px;
    width: 200px;
}

input[type=file] {
    position: relative;
    text-align: right;
    z-index: 2;
    width: 100%;
} */

.import-main {
    position: relative;
    height: 100%;
}

.import-input {
    height: 56%;
}

.import-table {
    text-align: center;
    height: 40%;
}

.import-table table {
    text-align: center;
}

.import-table table tbody {
    text-align: center;
}